import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { NgControl, FormsModule } from '@angular/forms';
import { AbstractTuiNullableControl, tuiIsNativeFocused, tuiAsFocusableItemAccessor, tuiAsControl } from '@taiga-ui/cdk';
import { TUI_MODE, TUI_CHECKBOX_OPTIONS, MODE_PROVIDER } from '@taiga-ui/core';
import * as i1 from '@taiga-ui/kit/components/checkbox';
import { TuiCheckboxComponent, TuiCheckboxModule } from '@taiga-ui/kit/components/checkbox';
import * as i3 from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class TuiCheckboxLabeledComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, mode$, options) {
    super(control, cdr);
    this.mode$ = mode$;
    this.options = options;
    this.size = this.options.size;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.checkbox) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  /** @deprecated use 'value' setter */
  onModelChange(value) {
    this.value = value;
  }
}
TuiCheckboxLabeledComponent.ɵfac = function TuiCheckboxLabeledComponent_Factory(t) {
  return new (t || TuiCheckboxLabeledComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_MODE), i0.ɵɵdirectiveInject(TUI_CHECKBOX_OPTIONS));
};
TuiCheckboxLabeledComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiCheckboxLabeledComponent,
  selectors: [["tui-checkbox-labeled"]],
  viewQuery: function TuiCheckboxLabeledComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiCheckboxComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.checkbox = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiCheckboxLabeledComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("$.data-mode.attr", function TuiCheckboxLabeledComponent___data_mode_attr_HostBindingHandler() {
        return ctx.mode$;
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiCheckboxLabeledComponent), tuiAsControl(TuiCheckboxLabeledComponent), MODE_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c0,
  decls: 4,
  vars: 12,
  consts: [[1, "t-wrapper"], [3, "ngModelChange", "focusedChange", "disabled", "focusable", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "pseudoInvalid", "readOnly", "size", "ngModel"], [1, "t-content"]],
  template: function TuiCheckboxLabeledComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "label", 0)(1, "tui-checkbox", 1);
      i0.ɵɵtwoWayListener("ngModelChange", function TuiCheckboxLabeledComponent_Template_tui_checkbox_ngModelChange_1_listener($event) {
        i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
        return $event;
      });
      i0.ɵɵlistener("focusedChange", function TuiCheckboxLabeledComponent_Template_tui_checkbox_focusedChange_1_listener($event) {
        return ctx.onFocused($event);
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(2, "div", 2);
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("nativeId", ctx.nativeId)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.pseudoFocus)("pseudoHover", ctx.pseudoHover)("pseudoInvalid", ctx.computedInvalid)("readOnly", ctx.readOnly)("size", ctx.size);
      i0.ɵɵtwoWayProperty("ngModel", ctx.value);
      i0.ɵɵadvance();
      i0.ɵɵclassProp("t-content_disabled", ctx.computedDisabled);
    }
  },
  dependencies: [i1.TuiCheckboxComponent, i2.NgControlStatus, i2.NgModel],
  styles: ["[_nghost-%COMP%]{position:relative;display:block;line-height:0}._disabled[_nghost-%COMP%], ._readonly[_nghost-%COMP%]{pointer-events:none}.t-wrapper[_ngcontent-%COMP%]{display:inline-flex;max-width:100%;cursor:pointer}._disabled[_nghost-%COMP%]   .t-wrapper[_ngcontent-%COMP%]{cursor:default}[data-mode=onDark][_nghost-%COMP%]   .t-wrapper[_ngcontent-%COMP%]{color:var(--tui-text-01-night)}.t-content[_ngcontent-%COMP%]{font:var(--tui-font-text-s);line-height:1rem;word-wrap:break-word;min-width:0}.t-content_disabled[_ngcontent-%COMP%]{opacity:var(--tui-disabled-opacity)}[data-size=m][_nghost-%COMP%]   .t-content[_ngcontent-%COMP%]{margin-left:.5rem}[data-size=l][_nghost-%COMP%]   .t-content[_ngcontent-%COMP%]{font:var(--tui-font-text-m);line-height:1.5rem;margin-left:.75rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckboxLabeledComponent, [{
    type: Component,
    args: [{
      selector: 'tui-checkbox-labeled',
      templateUrl: './checkbox-labeled.template.html',
      styleUrls: ['./checkbox-labeled.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiCheckboxLabeledComponent), tuiAsControl(TuiCheckboxLabeledComponent), MODE_PROVIDER],
      host: {
        '($.data-mode.attr)': 'mode$',
        '[attr.data-size]': 'size'
      }
    }]
  }], function () {
    return [{
      type: i2.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i3.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_CHECKBOX_OPTIONS]
      }]
    }];
  }, {
    checkbox: [{
      type: ViewChild,
      args: [TuiCheckboxComponent]
    }],
    size: [{
      type: Input
    }]
  });
})();
class TuiCheckboxLabeledModule {}
TuiCheckboxLabeledModule.ɵfac = function TuiCheckboxLabeledModule_Factory(t) {
  return new (t || TuiCheckboxLabeledModule)();
};
TuiCheckboxLabeledModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCheckboxLabeledModule
});
TuiCheckboxLabeledModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, TuiCheckboxModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckboxLabeledModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, TuiCheckboxModule],
      declarations: [TuiCheckboxLabeledComponent],
      exports: [TuiCheckboxLabeledComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCheckboxLabeledComponent, TuiCheckboxLabeledModule };
