import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ViewChild, Input, HostBinding, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i2 from '@taiga-ui/cdk';
import { AbstractTuiNullableControl, tuiIsNativeFocused, tuiAsFocusableItemAccessor, tuiAsControl, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_CHECKBOX_OPTIONS, TuiPrimitiveCheckboxModule } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
const _c0 = ["focusableElement"];
class TuiCheckboxComponent extends AbstractTuiNullableControl {
  constructor(control, options, cdr) {
    super(control, cdr);
    this.options = options;
    this.size = this.options.size;
  }
  get nativeFocusableElement() {
    return !this.focusableElement || this.computedDisabled ? null : this.focusableElement.nativeElement;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement);
  }
  get computedFocusable() {
    return this.interactive && this.focusable;
  }
  /** @deprecated use 'value' setter */
  onChecked(checked) {
    this.value = checked;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  onFocusVisible(focusVisible) {
    this.updateFocusVisible(focusVisible);
  }
}
TuiCheckboxComponent.ɵfac = function TuiCheckboxComponent_Factory(t) {
  return new (t || TuiCheckboxComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(TUI_CHECKBOX_OPTIONS), i0.ɵɵdirectiveInject(ChangeDetectorRef));
};
TuiCheckboxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiCheckboxComponent,
  selectors: [["tui-checkbox"]],
  viewQuery: function TuiCheckboxComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.focusableElement = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiCheckboxComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiCheckboxComponent), tuiAsControl(TuiCheckboxComponent)]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 11,
  consts: [["focusableElement", ""], [3, "disabled", "focused", "hovered", "invalid", "pressed", "size", "value"], ["automation-id", "tui-checkbox__native", "type", "checkbox", 1, "t-native", 3, "tuiCheckedChange", "tuiFocusedChange", "tuiFocusVisibleChange", "disabled", "id", "tuiFocusable", "tuiChecked"]],
  template: function TuiCheckboxComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "tui-primitive-checkbox", 1)(1, "input", 2, 0);
      i0.ɵɵtwoWayListener("tuiCheckedChange", function TuiCheckboxComponent_Template_input_tuiCheckedChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
        return i0.ɵɵresetView($event);
      });
      i0.ɵɵlistener("tuiFocusedChange", function TuiCheckboxComponent_Template_input_tuiFocusedChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocused($event));
      })("tuiFocusVisibleChange", function TuiCheckboxComponent_Template_input_tuiFocusVisibleChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onFocusVisible($event));
      });
      i0.ɵɵelementEnd()();
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabled", ctx.disabled)("focused", ctx.computedFocusVisible)("hovered", ctx.pseudoHover)("invalid", ctx.computedInvalid)("pressed", ctx.pseudoActive)("size", ctx.size)("value", ctx.value);
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.disabled)("id", ctx.id)("tuiFocusable", ctx.computedFocusable);
      i0.ɵɵtwoWayProperty("tuiChecked", ctx.value);
    }
  },
  dependencies: [i1.TuiPrimitiveCheckboxComponent, i2.TuiCheckedDirective, i2.TuiFocusableDirective, i2.TuiFocusedDirective, i2.TuiFocusVisibleDirective],
  styles: ["[_nghost-%COMP%]{font:var(--tui-font-text-s);color:var(--tui-text-01);position:relative;display:block;flex-shrink:0}[data-size=m][_nghost-%COMP%]{width:1rem;height:1rem}[data-size=l][_nghost-%COMP%]{width:1.5rem;height:1.5rem}._readonly[_nghost-%COMP%]   tui-primitive-checkbox[_ngcontent-%COMP%]{pointer-events:none}.t-native[_ngcontent-%COMP%]{padding:0;margin:0;border-width:0;border-radius:inherit;background:none;font-size:inherit;line-height:inherit;font-weight:inherit;color:inherit;caret-color:currentColor;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;word-break:keep-all;-webkit-text-fill-color:currentColor;position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}.t-native[_ngcontent-%COMP%]:-webkit-autofill, .t-native[_ngcontent-%COMP%]:-webkit-autofill:hover, .t-native[_ngcontent-%COMP%]:-webkit-autofill:focus{caret-color:var(--tui-base-09);border-radius:inherit;color:inherit!important;background-color:transparent!important;-webkit-text-fill-color:var(--tui-text-01)!important;border-color:var(--tui-autofill);-webkit-box-shadow:0 0 0 100rem var(--tui-autofill) inset!important}@supports (-webkit-touch-callout: none){.t-native[_ngcontent-%COMP%]:active{font-size:1rem}}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'tui-checkbox',
      templateUrl: './checkbox.template.html',
      styleUrls: ['./checkbox.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiCheckboxComponent), tuiAsControl(TuiCheckboxComponent)]
    }]
  }], function () {
    return [{
      type: i3.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_CHECKBOX_OPTIONS]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }];
  }, {
    focusableElement: [{
      type: ViewChild,
      args: ['focusableElement']
    }],
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiCheckboxModule {}
TuiCheckboxModule.ɵfac = function TuiCheckboxModule_Factory(t) {
  return new (t || TuiCheckboxModule)();
};
TuiCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiCheckboxModule
});
TuiCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule, TuiPrimitiveCheckboxModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiCheckedModule, TuiFocusableModule, TuiFocusedModule, TuiFocusVisibleModule, TuiPrimitiveCheckboxModule],
      declarations: [TuiCheckboxComponent],
      exports: [TuiCheckboxComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiCheckboxComponent, TuiCheckboxModule };
